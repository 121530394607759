





















































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import HeaderApi from '@/api/system/HeaderApi';
import UserUtil from '@/utils/UserUtil';
import CryptoUtil from '@/utils/CryptoUtil';
@Component({
  name: 'UpdatePassword'
})
export default class UpdatePassword extends Vue {
  // ref
  @Ref() readonly formRef;
  @Prop({ default: false }) private visible!: boolean;
  @Prop({ default: true }) showClose!: boolean;
  @Prop({ default: false }) private changePasswordData: any;
  //   表单数据
  formData: any = {
    oldPassword: '',
    password: ''
  };
  tips: string = '';

  confirmPasswordFlag: boolean = false;
  newPassword: string = '';
  //   验证规则
  formRules: Record<any, any> = {
    oldPassword: {
      required: true,
      message: this.$t('lang_please_entry_old_password'),
      trigger: 'blur'
    },
    password: [
      {
        validator: this.validatePass,
        trigger: 'blur',
        required: true
      },
      {
        min: 8,
        max: 16,
        message: '长度在 8 到 16 个字符',
        trigger: 'blur'
      }
    ]
  };
  mounted() {
    this.$nextTick(() => {
      let changeFlag = localStorage.getItem('changeFlag');
      if (changeFlag && this.changePasswordData && this.changePasswordData.changeFlag) {
        this.formData.oldPassword = this.changePasswordData.defaultPassword;
        this.tips = this.changePasswordData.tips;
      } else {
        this.formData.oldPassword = this.tips = '';
      }
    });
  }
  confirmPassword() {
    this.confirmPasswordFlag = true;
  }
  vidoe() {
    if (this.formData.password != this.newPassword || !this.newPassword) {
      this.confirmPasswordFlag = true;
    } else {
      this.confirmPasswordFlag = false;
    }
  }
  /**
   * 密码验证
   */
  validatePass(rule, value, callback) {
    // let passwordReg = /^[A-Za-z0-9\!\@\#\$\%\^\&\*\(\)\-\_\=\+]{8,16}$/;
    let passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*()_=])[\da-zA-Z~!@#$%^&*()_=]{8,16}$/;
    if (!value) {
      let str: any = this.$t('lang_please_entry_new_password');
      callback(new Error(str));
    } else if (value == this.formData.oldPassword) {
      let str: any = this.$t('新密码不能和旧密码一样');
      callback(new Error(str));
    } else if (value == 'ebYPeApx7%') {
      let str: any = this.$t('新密码不能和初始密码一样');
      callback(new Error(str));
    } else {
      if (!passwordReg.test(value)) {
        callback(new Error('密码格式不正确'));
      } else {
        callback();
      }
    }
  }
  save() {
    if (this.formData.password != this.newPassword || !this.newPassword) {
      // this.confirmPasswordFlag = true;
      return this.$message.warning('两次密码不一致');
    }
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      this.formData.userId = UserUtil.getUser().id;
      HeaderApi.password({
        oldPassword: CryptoUtil.setBase64(this.formData.oldPassword),
        password: CryptoUtil.setBase64(this.formData.password),
        userId: this.formData.userId
      }).then((res: Record<any, any>) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          // 关闭弹窗
          this.handleCloseDialog();
          this.confirmPasswordFlag = false;
        } else {
          this.$message.error(res.message);
        }
      });
    });
  }
  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.formData.oldPassword = this.tips = '';
    localStorage.removeItem('changeFlag');
    this.$emit('update:visible', value);
  }
  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
